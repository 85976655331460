'use client';

import { LIVE_BLOG_LOAD_BELOW_ITEMS_EVENT } from '@haaretz/s-consts';
import useIntersectionObserver from '@haaretz/s-use-intersection-observer/common';
import React from 'react';
import s9 from 'style9';

const c = s9.create({
  observer: {
    width: '1px',
    height: '1px',
    position: 'relative',
    pointerEvents: 'none',
    userSelect: 'none',
    touchAction: 'none',
  },
});

export default function LiveBlogLoadMoreObserver() {
  const loadMorePixelRef = React.useRef<HTMLDivElement>(null);

  const loadMoreObserverCb = React.useCallback((entries: IntersectionObserverEntry[]) => {
    const element = entries[0];
    const isFullyInViewPort = element.isIntersecting;

    if (isFullyInViewPort) {
      const scrollEvent: CustomEvent<string> = new CustomEvent(LIVE_BLOG_LOAD_BELOW_ITEMS_EVENT);

      document.dispatchEvent(scrollEvent);
    }
  }, []);

  const observerParams = React.useMemo(
    () => ({
      rootMargin: '2000px 0px 2000px 0px',
      cb: loadMoreObserverCb,
      elements: loadMorePixelRef,
    }),
    [loadMoreObserverCb]
  );

  useIntersectionObserver(observerParams);

  return <div className={s9(c.observer)} ref={loadMorePixelRef} />;
}
