import isStorybook from '@haaretz/s-common-utils/isStorybook';

import FaviconBadge from '../utils/loadMoreLiveBlogItems/FaviconBadge';

import type { LiveBlogState, LiveBlogAction } from '../types/liveBlogState.types';

export default function liveBlogReducer(
  state: LiveBlogState,
  action: LiveBlogAction
): LiveBlogState {
  let itemsAbovePreloaded: JSX.Element[];

  switch (action.type) {
    case 'LOAD_ITEMS_ABOVE_REQUEST':
      return { ...state, itemsAboveLoading: true };
    case 'LOAD_ITEMS_ABOVE_SUCCESS':
      itemsAbovePreloaded = [...action.payload.items, ...state.itemsAbovePreloaded];

      if (!isStorybook()) FaviconBadge(itemsAbovePreloaded.length);

      return {
        ...state,
        itemsAbovePreloaded,
        firstItemId: action.payload.firstItemId ?? state.firstItemId,
        itemsAboveLoading: false,
        totalCount: action.payload.totalCount || state.totalCount,
      };
    case 'LOAD_ITEMS_ABOVE_FAILURE':
      return { ...state, itemsAboveLoading: false };
    case 'SHOW_ITEMS_ABOVE':
      if (!isStorybook()) FaviconBadge(0);

      return {
        ...state,
        itemsAbove: [...state.itemsAbovePreloaded, ...(state.itemsAbove ?? [])],
        itemsAbovePreloaded: [],
      };
    case 'LOAD_ITEMS_BELOW_REQUEST':
      return { ...state, itemsBelowLoading: true };
    case 'LOAD_ITEMS_BELOW_SUCCESS':
      return { ...state, itemsBelow: action.payload, itemsBelowLoading: false };
    case 'LOAD_ITEMS_BELOW_FAILURE':
      return { ...state, itemsBelowLoading: false };
    case 'SHOW_ITEMS_BELOW':
      return {
        ...state,
        showItemsBelow: true,
      };
    case 'CHANGE_ORDER_REQUEST':
      return { ...state, itemsLoading: true, firstItemId: null, lastItemId: null };
    case 'CHANGE_ORDER_SUCCESS':
      if (!isStorybook) FaviconBadge(0);

      return {
        ...state,
        itemsAbove: [],
        itemsAbovePreloaded: [],
        items: action.payload.items,
        itemsLoading: false,
        itemsBelow: [],
        showItemsBelow: false,
        order: action.payload.order,
        firstItemId: action.payload.firstItemId,
        lastItemId: action.payload.lastItemId,
        totalCount: action.payload.totalCount || state.totalCount,
      };
    case 'CHANGE_ORDER_FAILURE':
      return { ...state, itemsLoading: false };
    case 'SET_FEATURED_ITEM_ID':
      return { ...state, featuredItemId: action.payload };
    case 'REMOVE_FEATURED_ITEM_ID':
      return { ...state, featuredItemId: null };
    case 'SET_FIRST_ITEM_ID':
      return { ...state, firstItemId: action.payload };
    case 'SET_LAST_ITEM_ID':
      return { ...state, lastItemId: action.payload };
    case 'REMOVE_FIRST_ITEM_ID':
      return { ...state, firstItemId: null };
    case 'REMOVE_LAST_ITEM_ID':
      return { ...state, lastItemId: null };
    case 'CLEAR_ITEMS':
      if (!isStorybook) FaviconBadge(0);

      return {
        ...state,
        itemsAbove: [],
        itemsAbovePreloaded: [],
        items: [],
        itemsBelow: [],
        showItemsBelow: false,
        itemsAboveLoading: false,
        itemsLoading: false,
        itemsBelowLoading: false,
        firstItemId: null,
        lastItemId: null,
        order: 'desc',
      };
    default:
      return state;
  }
}
