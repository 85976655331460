'use client';

import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import Button from '@haaretz/s-button';
import {
  LIVE_BLOG_LOAD_ALL_ITEMS_EVENT,
  LIVE_BLOG_LOAD_BELOW_ITEMS_EVENT,
} from '@haaretz/s-consts';
import useBi from '@haaretz/s-use-bi';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';
import s9 from 'style9';

import useLiveBlog, { useLiveBlogActions } from '../LiveBlogProvider';

import LiveBlogLoadMoreObserver from './LiveBlogLoadMoreObserver';

const c = s9.create({
  loadMoreButton: {
    marginTop: space(4),
    display: 'flex',
    margin: '0 auto',
  },
});

function LiveBlogEndItems() {
  const state = useLiveBlog();
  const { loadItemsBelow, showItemsBelow } = useLiveBlogActions();
  const biAction = useBi();
  const [buttonState, setButtonState] = React.useState<'default' | 'hidden' | 'busy'>('hidden');

  const loadMoreButtonInView = React.useRef(false);

  React.useEffect(() => {
    loadMoreButtonInView.current = false;
  }, []);

  useDocumentEventListener(
    LIVE_BLOG_LOAD_ALL_ITEMS_EVENT,
    ({ detail }) => {
      setButtonState('hidden');
      showItemsBelow();
      loadItemsBelow({ lastItemId: state.lastItemId, order: state.order, clickedItemId: detail });
    },
    true
  );

  useDocumentEventListener(
    LIVE_BLOG_LOAD_BELOW_ITEMS_EVENT,
    () => {
      loadItemsBelow({ lastItemId: state.lastItemId, order: state.order });
    },
    true
  );

  React.useEffect(() => {
    if (state.showItemsBelow && buttonState !== 'default') {
      setButtonState('default');
    }
  }, [buttonState, state.showItemsBelow]);

  React.useEffect(() => {
    setButtonState(state.itemsBelow.length ? 'default' : 'hidden');
  }, [state.itemsBelow.length]);

  const button =
    buttonState !== 'hidden' ? (
      <Button
        styleExtend={[c.loadMoreButton]}
        onClick={() => {
          if (state.itemsAboveLoading) {
            setButtonState('busy');
            biAction({
              action_id: 148,
              feature_type: 'Content',
              feature: 'more live blog items',
              campaign_name: 'load all', // We are always loading all now
            });
          }

          showItemsBelow();
        }}
        {...(buttonState === 'busy' && state.itemsBelowLoading
          ? { state: 'busy', busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }) }
          : { state: 'auto' })}
      >
        {fork({ default: 'לעדכונים נוספים', hdc: 'Load more' })}
      </Button>
    ) : null;

  return (
    <>
      {state.showItemsBelow && !state.itemsBelowLoading
        ? state.itemsBelow
        : button || <LiveBlogLoadMoreObserver />}
    </>
  );
}

export default function LiveBlogEndItemsWrapper() {
  const state = useLiveBlog();

  const isLoaded = useLoadEvent();

  if (!isLoaded || !state.lastItemId || (!state.featuredItemId && state.totalCount <= 10)) {
    return null;
  }

  return <LiveBlogEndItems />;
}
