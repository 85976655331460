'use client';
import config from '@haaretz/l-config';
import useClientQuery from '@haaretz/s-client-data-hooks/useClientQuery';
import { site } from '@haaretz/s-consts';
import * as React from 'react';

import NewsletterRegistrationView from './NewsletterRegistration.view';

import type { RegistrationRichTextElementFragment } from '@haaretz/s-fragments/RegistrationRichTextElement';
import type { StyleExtend, InlineStyles, Site } from '@haaretz/s-types';
// `c` is short for `classNames`
export interface NewsletterRegistrationPersonalProps
  extends Pick<RegistrationRichTextElementFragment, 'frequency'> {
  authorIds: string[] | null;
  children: JSX.Element;
  /** The Children to be rendered inside `<NewsletterRegistration>` */
  sectionId?: string | null;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyNewsletterRegistration(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <NewsletterRegistration
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <NewsletterRegistration styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export type RegistrationStatus = 'notRegistered' | 'success' | 'pending';

const alertsUrl = config.get('alertsUri');

function NewsletterRegistrationPersonal({
  authorIds,
  inlineStyle,
  styleExtend = [],
  sectionId,
  frequency,
  children,
}: NewsletterRegistrationPersonalProps) {
  const { data, error, isLoading } = useClientQuery<
    {
      version: string;
      registrationElement: RegistrationRichTextElementFragment;
    },
    {
      sectionId: string | null;
      site: Site;
      authorIds: string;
    }
  >({
    url: `${alertsUrl}/registration-element`,
    variables: {
      sectionId: sectionId || null,
      site,
      authorIds: authorIds?.join(',') || '',
    },
    clientOptions: { enabled: !!sectionId },
    cacheKey: 'newsletterRegistration',
  });

  if (!data || error || isLoading) return children;
  const { registrationElement } = data;
  if (!registrationElement || !registrationElement.title) return null;

  return (
    <NewsletterRegistrationView
      {...registrationElement}
      frequency={frequency}
      inlineStyle={inlineStyle}
      styleExtend={styleExtend}
    />
  );
}

export default function NewsletterRegistrationPersonalWrapper({
  children,
  ...props
}: NewsletterRegistrationPersonalProps) {
  return (
    <React.Suspense fallback={children}>
      <NewsletterRegistrationPersonal {...props}>{children}</NewsletterRegistrationPersonal>
    </React.Suspense>
  );
}
