import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type RejectCommentMutationVariables = Types.Exact<{
  input: Types.RejectCommentInputType;
}>;


export type RejectCommentMutation = (
  { rejectComment: (
    { error?: string | null, status?: string | null }
    & { __typename: 'RejectCommentResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const RejectCommentDocument = `
    mutation RejectComment($input: RejectCommentInputType!) {
  rejectComment(input: $input) {
    error
    status
  }
}
    `;
export const useRejectCommentMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RejectCommentMutation, TError, RejectCommentMutationVariables, TContext>) =>
    useMutation<RejectCommentMutation, TError, RejectCommentMutationVariables, TContext>(
      ['RejectComment'],
      (variables?: RejectCommentMutationVariables) => fetch<RejectCommentMutation, RejectCommentMutationVariables>(RejectCommentDocument, variables)(),
      options
    );
useRejectCommentMutation.getKey = () => ['RejectComment'];

useRejectCommentMutation.fetcher = (variables: RejectCommentMutationVariables, options?: RequestInit['headers']) => fetch<RejectCommentMutation, RejectCommentMutationVariables>(RejectCommentDocument, variables, options);