import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type SelectEditorsPickMutationVariables = Types.Exact<{
  input: Types.SelectEditorsPickInputType;
}>;


export type SelectEditorsPickMutation = (
  { selectEditorsPick: (
    { error?: string | null, status?: string | null }
    & { __typename: 'SelectEditorsPickResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const SelectEditorsPickDocument = `
    mutation SelectEditorsPick($input: SelectEditorsPickInputType!) {
  selectEditorsPick(input: $input) {
    error
    status
  }
}
    `;
export const useSelectEditorsPickMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SelectEditorsPickMutation, TError, SelectEditorsPickMutationVariables, TContext>) =>
    useMutation<SelectEditorsPickMutation, TError, SelectEditorsPickMutationVariables, TContext>(
      ['SelectEditorsPick'],
      (variables?: SelectEditorsPickMutationVariables) => fetch<SelectEditorsPickMutation, SelectEditorsPickMutationVariables>(SelectEditorsPickDocument, variables)(),
      options
    );
useSelectEditorsPickMutation.getKey = () => ['SelectEditorsPick'];

useSelectEditorsPickMutation.fetcher = (variables: SelectEditorsPickMutationVariables, options?: RequestInit['headers']) => fetch<SelectEditorsPickMutation, SelectEditorsPickMutationVariables>(SelectEditorsPickDocument, variables, options);