'use client';

import { LIVE_BLOG_SCROLL_TO_ITEM_EVENT } from '@haaretz/s-consts';
import useBi from '@haaretz/s-use-bi';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import {
  changeOrderAction,
  loadItemsBelowAction,
  showItemsBelowAction,
  loadItemsAboveAction,
  showItemsAboveAction,
  removeItemIdAction,
  setItemIdAction,
  clearItemsAction,
} from '../../state/LiveBlog.actions';
import liveBlogReducer from '../../state/Liveblog.reducer';
import createLiveBlogState from '../../state/Liveblog.state';
import { LiveBlogState } from '../../types/liveBlogState.types';

import type { LiveBlogArticleFragment } from '@haaretz/s-fragments/articles/LiveBlog.article';

interface LiveBlogProviderProviderContextProps extends LiveBlogState {}

interface LiveBlogActionProviderProviderContextProps {
  changeOrder: (order: LiveBlogState['order'], lastItemId?: LiveBlogState['lastItemId']) => void;
  loadItemsAbove: (args: Parameters<typeof loadItemsAboveAction>[1]) => void;
  showItemsAbove: () => void;
  loadItemsBelow: (args: Parameters<typeof loadItemsBelowAction>[1]) => void;
  showItemsBelow: () => void;
  setItemId: (type: Parameters<typeof setItemIdAction>[1], itemId: string) => void;
  removeItemId: (type: Parameters<typeof removeItemIdAction>[1]) => void;
  clearItems: () => void;
}

const LiveBlogProviderContext = React.createContext<
  LiveBlogProviderProviderContextProps | undefined
>(undefined);

const LiveBlogActionProviderContext = React.createContext<
  LiveBlogActionProviderProviderContextProps | undefined
>(undefined);

interface LiveBlogProviderProps
  extends Pick<LiveBlogArticleFragment, 'totalCount'>,
    Pick<LiveBlogState, 'lastItemId' | 'firstItemId'> {}

export function LiveBlogProvider({
  children,
  ...props
}: React.PropsWithChildren<LiveBlogProviderProps>) {
  const biAction = useBi();

  const searchParams = useSearchParams();
  const featuredLiveBlogItemId = searchParams.get('liveBlogItemId');

  const prevFeatureadItemIdRef = React.useRef<string | null>(null);

  const [state, dispatch] = React.useReducer(
    liveBlogReducer,
    createLiveBlogState({
      featuredItemId: featuredLiveBlogItemId,
      ...props,
    })
  );

  const changeOrder = React.useCallback(
    (order: LiveBlogState['order'], lastItemId?: LiveBlogState['lastItemId']) => {
      if (!state.itemsLoading) {
        changeOrderAction(dispatch, order, lastItemId);
      }
    },
    [state.itemsLoading]
  );

  const loadItemsAbove = React.useCallback(
    (args: Parameters<typeof loadItemsAboveAction>[1]) => {
      if (!state.itemsAboveLoading) {
        loadItemsAboveAction(dispatch, args, biAction);
      }
    },
    [biAction, state.itemsAboveLoading]
  );

  const loadItemsBelow = React.useCallback(
    (args: Parameters<typeof loadItemsBelowAction>[1]) => {
      if (!state.itemsBelowLoading) {
        loadItemsBelowAction(dispatch, args, biAction);
      }
    },
    [biAction, state.itemsBelowLoading]
  );

  const showItemsAbove = React.useCallback(() => {
    showItemsAboveAction(dispatch);
  }, []);

  const showItemsBelow = React.useCallback(() => {
    showItemsBelowAction(dispatch);
  }, []);

  const clearItems = React.useCallback(() => {
    clearItemsAction(dispatch);
  }, []);

  const removeItemId = React.useCallback((type: Parameters<typeof removeItemIdAction>[1]) => {
    removeItemIdAction(dispatch, type);
  }, []);

  const setItemId = React.useCallback(
    (type: Parameters<typeof setItemIdAction>[1], itemId: string) => {
      setItemIdAction(dispatch, type, itemId);

      if (type === 'FEATURED') {
        clearItems();
        loadItemsAbove({ firstItemId: itemId, order: 'desc' });
      }
    },
    [clearItems, loadItemsAbove]
  );

  React.useEffect(() => {
    const isUpdatedFeaturedItemId = prevFeatureadItemIdRef.current !== featuredLiveBlogItemId;

    if (featuredLiveBlogItemId && isUpdatedFeaturedItemId) {
      setItemId('FEATURED', featuredLiveBlogItemId);
    }

    if (isUpdatedFeaturedItemId) {
      prevFeatureadItemIdRef.current = featuredLiveBlogItemId;
    }
  }, [featuredLiveBlogItemId, setItemId]);

  const actions = React.useMemo(
    () => ({
      changeOrder,
      loadItemsAbove,
      loadItemsBelow,
      showItemsAbove,
      showItemsBelow,
      setItemId,
      removeItemId,
      clearItems,
    }),
    [
      changeOrder,
      loadItemsAbove,
      loadItemsBelow,
      removeItemId,
      setItemId,
      showItemsAbove,
      showItemsBelow,
      clearItems,
    ]
  );

  useDocumentEventListener(
    LIVE_BLOG_SCROLL_TO_ITEM_EVENT,
    ({ detail }) => {
      scrollToItem();

      function scrollToItem(attempt = 0) {
        const item = document.getElementById(detail);

        if (item) {
          window.history.pushState(null, '', `#${detail}`);

          item.scrollIntoView({ behavior: 'smooth' });
        } else if (attempt < 10) {
          const nextAttempt = attempt + 1;

          setTimeout(() => scrollToItem(nextAttempt), 100 * nextAttempt);
        }
      }
    },
    true
  );

  return (
    <LiveBlogProviderContext.Provider value={state}>
      <LiveBlogActionProviderContext.Provider value={actions}>
        {children}
      </LiveBlogActionProviderContext.Provider>
    </LiveBlogProviderContext.Provider>
  );
}

export default function useLiveBlog() {
  const context = React.useContext(LiveBlogProviderContext);

  if (context === undefined) {
    throw new Error('useLiveBlog must be used within a LiveBlogProvider');
  }

  return context;
}

export function useLiveBlogActions() {
  const context = React.useContext(LiveBlogActionProviderContext);

  if (context === undefined) {
    throw new Error('useLiveBlog must be used within a LiveBlogProvider');
  }

  return context;
}
