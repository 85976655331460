'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import useLiveBlog from '@haaretz/s-live-blog/LiveBlogProvider';

import type { LiveBlogItemFragment } from '@haaretz/s-fragments/LiveBlogItem';

const c = s9.create({
  item: {
    position: 'relative',
    display: 'grid',
    overflow: 'visible',
    rowGap: space(3),
  },
  border: {
    paddingBottom: space(2),

    ...border({
      color: color('neutral200'),
      side: 'top',
      style: 'solid',
      width: '4px',
      spacing: 6,
    }),
  },
  firstItemBorder: {
    ...border({
      color: color('neutral200'),
      side: 'top',
      style: 'solid',
      width: '4px',
      spacing: 9,
    }),

    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...border({
            color: color('neutral200'),
            side: 'top',
            style: 'solid',
            width: '4px',
            spacing: 8,
          }),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...border({
            color: color('neutral200'),
            side: 'top',
            style: 'solid',
            width: '4px',
            spacing: 5,
          }),
        },
      })
    ),
  },
  featureItem: {
    borderRadius: radius('xLarge'),
    backgroundColor: color('neutral300'),
    paddingTop: space(5),
    paddingBottom: space(5),
    paddingInlineStart: space(2),
    paddingInlineEnd: space(2),
    ...merge(
      mq({ until: 'l', value: { paddingTop: space(5), paddingBottom: space(5) } }),
      mq({
        from: 'l',
        value: {
          paddingInlineStart: space(4),
          paddingInlineEnd: space(4),
          paddingTop: space(6),
          paddingBottom: space(6),
        },
      })
    ),
  },
  withoutBorder: {
    paddingTop: space(2),
    paddingBottom: space(2),
    border: 'none',
  },
});
type LiveBlogItemWrapperProps = {
  children: React.ReactNode;
  itemId: LiveBlogItemFragment['itemId'];
};

export default function LiveBlogItemClientWrapper({ children, itemId }: LiveBlogItemWrapperProps) {
  const state = useLiveBlog();

  const isFeaturedItem = state.featuredItemId === itemId;

  return (
    <div
      className={s9(
        c.item,
        c.border,
        isFeaturedItem && c.withoutBorder,
        isFeaturedItem && c.featureItem,
        state.firstItemId === itemId && c.firstItemBorder
      )}
      id={itemId ?? undefined}
    >
      {children}
    </div>
  );
}
