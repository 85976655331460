'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { getProgressStyle } from '../utils/audioPlayerUtils';

const c = s9.create({
  base: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginInlineEnd: space(-2.5),
          gridColumnStart: '1',
          gridColumnEnd: '2',
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  paBase: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: space(2),
    marginInlineStart: space(-2),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          justifyContent: 'start',
          marginInlineEnd: 0,
          gridRowStart: 4,
          gridRowEnd: 5,
          marginTop: space(3),
        },
      }),
      mq({
        from: 'l',
        value: {
          gridRowStart: 3,
          gridRowEnd: 4,
          marginInlineStart: space(1),
          justifyContent: 'start',
        },
      })
    ),
  },
  button: {
    position: 'relative',
    width: space(8),
    height: space(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'inherit',
  },
  paButton: {
    justifyContent: 'center',
  },
  buttonActive: {
    backgroundColor: color('neutral100', { opacity: 0.9 }),
    borderEndStartRadius: radius('xxLarge'),
    borderEndEndRadius: radius('xxLarge'),
  },
  icon: {
    fontSize: space(5),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          fontSize: space(6),
        },
      })
    ),
  },
  paIcon: {
    fontSize: space(4),
    ...merge(
      mq({ from: 's', until: 'l', value: { fontSize: space(5) } }),
      mq({ from: 'xxl', value: { fontSize: space(4) } })
    ),
  },
  input: {
    cursor: 'pointer',
    backgroundColor: color('neutral500'),
    borderRadius: radius('small'),
    height: space(1),
    transform: 'rotate(270deg)',
    outline: 'none',
    position: 'relative',
    WebkitAppearance: 'none',
    // The progress bar is left-to-right in both RTL and LTR, so we need to manually handle this
    left: fork({
      default: space(3),
      hdc: 'auto',
    }),
    right: fork({
      default: 'auto',
      hdc: space(3),
    }),
    top: space(4),
    width: space(14),
    '::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      backgroundColor: color('primary1000'),
      width: space(3),
      height: space(3),
      borderRadius: radius('circle'),
    },
    '::-moz-range-thumb': {
      WebkitAppearance: 'none',
      border: 'none',
      backgroundColor: color('primary1000'),
      width: space(3),
      height: space(3),
      borderRadius: radius('circle'),
    },
  },
  wrapInput: {
    position: 'absolute',
    left: 0,
    bottom: space(8),
    height: space(16),
    width: space(8),
    borderStartStartRadius: radius('xxLarge'),
    borderStartEndRadius: radius('xxLarge'),
    backgroundColor: color('neutral100', { opacity: 0.9 }),
  },
});
interface VolumeButtonProps {
  variant?: 'default' | 'podcastArticle';
}
export default function VolumeButton({ variant = 'default' }: VolumeButtonProps) {
  const startVolume = 0.5;
  const maxVolume = 1;
  const platform = usePlatform();
  const { audio } = useAudioPlayer();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const volumeRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [volumeInput, setVolumeInput] = React.useState<number>(startVolume);

  const onChangeVolume = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!audio || !inputRef.current) {
        return;
      }
      const newVolume = +e.target.value;
      setVolumeInput(newVolume);
      inputRef.current.style.background = getProgressStyle(newVolume, 1, variant);
      audio.volume = newVolume;
    },
    [audio, variant]
  );

  const handleMouseUp = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  React.useEffect(() => {
    const handleClickOutSide = (e: MouseEvent) => {
      const isClickInside =
        volumeRef.current && e.target && volumeRef.current.contains(e.target as Node);
      if (!isClickInside) {
        setIsOpen(false);
      }
    };
    if (isOpen === true) {
      document.addEventListener('click', handleClickOutSide);
    }
    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  }, [isOpen]);

  const isPodcastArticleVariant = variant === 'podcastArticle';
  const onVolumeClick = React.useCallback(() => {
    if (!audio) return;
    if (platform !== 'desktop' && isPodcastArticleVariant) {
      setVolumeInput(prev => (prev === 0 ? startVolume : 0));
      audio.volume = volumeInput === 0 ? startVolume : 0;
    } else {
      setIsOpen(prev => !prev);
    }
  }, [platform, isPodcastArticleVariant, audio, volumeInput]);

  const audioIcon = volumeInput >= 0.5 ? 'audio' : volumeInput === 0 ? 'audio-off' : 'audio-less';
  return (
    <div className={s9(c.base, isPodcastArticleVariant && c.paBase)}>
      <button
        onClick={onVolumeClick}
        className={s9(c.button, isOpen && c.buttonActive, isPodcastArticleVariant && c.paButton)}
        ref={volumeRef}
      >
        {isOpen && (
          <div className={s9(c.wrapInput)}>
            <input
              ref={inputRef}
              id="volume"
              dir="ltr"
              type="range"
              className={s9(c.input)}
              style={{
                background: getProgressStyle(volumeInput, maxVolume),
              }}
              value={volumeInput}
              min={0}
              max={maxVolume}
              step={0.05}
              onChange={onChangeVolume}
              onMouseUp={handleMouseUp}
              onTouchEnd={handleMouseUp}
            />
          </div>
        )}
        <Icon styleExtend={[c.icon, isPodcastArticleVariant && c.paIcon]} icon={audioIcon} />
        <VisuallyHidden>
          <output htmlFor="volume">Volume: {volumeInput}</output>
        </VisuallyHidden>
      </button>
    </div>
  );
}
