'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import Button from '@haaretz/s-button';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

const c = s9.create({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(3),
  },
  hidden: {
    opacity: 0,
    animationName: s9.keyframes({
      '0%': {
        opacity: 0,
        transform: 'translateY(30%)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    }),
    animationDelay: '0.1s',
    animationDuration: '0.5s',
    animationTimingFunction: 'ease-in-out',
  },
  notExpand: {
    ...merge(mq({ until: 's', value: { maxHeight: space(113), overflow: 'hidden' } })),
  },
  expand: {
    maxHeight: 'auto',
  },
  turnication: {
    height: '5lh',
    position: 'absolute',
    insetInlineEnd: 0,
    bottom: 0,
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, ${color('contentBg', { opacity: 0 })} 0%, ${color(
      'contentBg'
    )} 100%)` as string,
  },
  button: {
    justifySelf: 'start',
    marginTop: space(4),
    ...typesetter(-2),
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
});

type LiveBlogItemWrapperProps = {
  children: React.ReactNode[];
  isNewItem?: boolean;
  isFeaturedItem?: boolean;
};

const expandButtonText = fork({
  default: 'להרחבה',
  hdc: 'Expand',
});

const COLLAPSED_ITEM_COUNT = 2;

export default function LiveBlogItemClientWrapper({
  children,
  isFeaturedItem,
  isNewItem,
}: LiveBlogItemWrapperProps) {
  const platform = usePlatform();
  const bi = useBi();
  const [open, setIsOpen] = React.useState(false);
  const itemRef = React.useRef<HTMLDivElement | null>(null);

  function onAnimationEnd() {
    if (itemRef.current) {
      itemRef.current.style.opacity = '1';
    }
  }

  const availableExpand =
    platform !== 'desktop' && !isFeaturedItem && children.length > COLLAPSED_ITEM_COUNT;

  const collapsed = availableExpand && !open;

  const collapsedChildren = React.useMemo(() => {
    if (!collapsed || children.length <= COLLAPSED_ITEM_COUNT) return children;
    return children.slice(0, COLLAPSED_ITEM_COUNT);
  }, [collapsed, children]);

  return (
    <>
      <div
        className={s9(c.content, isNewItem && c.hidden, collapsed ? c.notExpand : c.expand)}
        ref={itemRef}
        onAnimationEnd={isNewItem ? onAnimationEnd : () => {}}
      >
        {collapsedChildren}
        {collapsed && <div className={s9(c.turnication)} />}
      </div>
      {collapsed && (
        <Button
          onClick={() => {
            setIsOpen(true);
            bi({
              action_id: 169,
              feature_type: 'Content',
              feature: 'Liveblog',
              campaign_name: 'expand item',
              campaign_details: expandButtonText,
            });
          }}
          priority="secondary"
          styleExtend={[c.button]}
        >
          {expandButtonText}
        </Button>
      )}
    </>
  );
}
