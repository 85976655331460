'use client';

import isStorybook from '@haaretz/s-common-utils/isStorybook';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import useLiveBlog, { useLiveBlogActions } from '../LiveBlogProvider';
import LoadNewItemsButton from '../LoadNewItemsButton';

import type { LiveBlogArticleFragment } from '@haaretz/s-fragments/articles/LiveBlog.article';

const c = s9.create({
  wrapper: {
    display: 'contents',
  },
  anchorPixel: {
    width: '1px',
    height: '1px',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
  },
});

const UPDATE_MIN_INTERVAL = 1;

const UPDATE_INTERVAL = 1000 * 60 * UPDATE_MIN_INTERVAL;

interface LiveBlogStartItemsProps extends Pick<LiveBlogArticleFragment, 'newerItemsCount'> {}

let counter = 0;

function LiveBlogStartItems({ newerItemsCount }: LiveBlogStartItemsProps) {
  counter = counter + 1;

  const searchParams = useSearchParams();
  const state = useLiveBlog();

  const { loadItemsAbove, showItemsAbove, removeItemId } = useLiveBlogActions();
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      loadItemsAbove({ firstItemId: state.firstItemId, order: state.order });
    }, UPDATE_INTERVAL); // NOTE: Every minute

    return () => clearInterval(intervalId);
  }, [loadItemsAbove, state.firstItemId, state.order]);

  React.useEffect(() => {
    if (isStorybook() && !state.itemsAbovePreloaded.length) {
      loadItemsAbove({ firstItemId: state.firstItemId, order: state.order });
    }
  }, [loadItemsAbove, state.firstItemId, state.order]);

  const goToPage = React.useCallback(
    function goToPage() {
      const params = new URLSearchParams(searchParams.toString());

      params.delete('liveBlogItemId');

      const newUrl = `?${params.toString()}`;

      window.history.replaceState(null, '', newUrl);
    },
    [searchParams]
  );

  const displayItems = React.useCallback(
    function displayItems() {
      goToPage();
      removeItemId('FEATURED');
      showItemsAbove();

      anchorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    [goToPage, removeItemId, showItemsAbove]
  );
  return (
    <div className={s9(c.wrapper)}>
      <div ref={anchorRef} className={s9(c.anchorPixel)} />
      <LoadNewItemsButton onClick={displayItems} newerItemsCount={newerItemsCount} />
      {state.itemsAbove}
    </div>
  );
}

export default function LiveBlogStartItemsWrapper(props: LiveBlogStartItemsProps) {
  const state = useLiveBlog();

  const isLoaded = useLoadEvent();

  const isInitialized = !isLoaded || !state.firstItemId;
  const isFeaturedItem = state.featuredItemId;
  const isAscOrder = state.order === 'asc';

  if (isAscOrder) return null;
  if (isInitialized && !isFeaturedItem) return null;

  return <LiveBlogStartItems {...props} />;
}
