'use client';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import getArticleIdFromURL from '@haaretz/s-url-utils/getArticleIdFromURL';
import useBi from '@haaretz/s-use-bi';
import s9 from 'style9';

const c = s9.create({
  fullArticle: {
    justifySelf: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  fullArticleText: {
    marginInlineEnd: space(1),
    fontWeight: 700,
    ...typesetter(-2),
  },
  arrow: {
    fontSize: space(4),
    transform: 'rotate(180deg)',
  },
});

const fullArticleText = fork({
  default: 'לכתבה המלאה',
  hdc: 'Read the full article',
});

export default function GoToFullArticle({ fullArticleLink }: { fullArticleLink: string }) {
  const bi = useBi();
  const nextArticleId = getArticleIdFromURL(fullArticleLink);

  return (
    <HtzLink
      href={fullArticleLink}
      className={s9(c.fullArticle)}
      onClick={() => {
        bi({
          feature_type: 'Content',
          feature: 'live blog item',
          action_id: 109,
          next_article_id: nextArticleId ?? undefined,
        });
      }}
    >
      <span className={s9(c.fullArticleText)}>{fullArticleText}</span>
      <Icon icon="arrow-right" styleExtend={[c.arrow]} />
    </HtzLink>
  );
}
