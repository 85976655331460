import type { LiveBlogState } from '../types/liveBlogState.types';

interface LiveBlogStateArgs
  extends Pick<LiveBlogState, 'featuredItemId' | 'firstItemId' | 'lastItemId'> {
  totalCount: LiveBlogState['totalCount'];
}

export default function createLiveBlogState({
  featuredItemId,
  firstItemId,
  lastItemId,
  totalCount,
}: LiveBlogStateArgs): LiveBlogState {
  return {
    itemsAboveLoading: false,
    itemsAbovePreloaded: [],
    itemsAbove: [],
    itemsLoading: false,
    items: [],
    itemsBelowLoading: false,
    itemsBelow: [],
    showItemsBelow: false,
    order: 'desc',
    featuredItemId: featuredItemId ?? null,
    firstItemId,
    lastItemId,
    totalCount,
  };
}
