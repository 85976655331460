'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import shadow from '@haaretz/l-shadow.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import useAppUserAgent, {
  NativeAppUserAgentContextProvider,
} from '@haaretz/s-use-native-application/useAppUserAgent';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { LiveBlogItemFragment } from '@haaretz/s-fragments/LiveBlogItem';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';
// `c` is short for `classNames`
const c = s9.create({
  base: {},
  shareBtn: {
    justifySelf: 'end',
    alignSelf: 'center',
    width: space(7),
    height: space(7),
  },
  shareBtnIcon: {
    fontSize: space(4),
    '--icon-color-override': color('neutral1300'),
  },
  clickAreaColor: {
    backgroundColor: color('primary400'),
    ':hover': {
      backgroundColor: color('primary600'),
    },
    ':focus': {
      backgroundColor: color('primary600'),
      ...shadow('medium'),
    },
  },

  clickAreaGeneralStyling: {
    borderRadius: radius('circle'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coppiedSuccess: {
    backgroundColor: color('quaternary400'),
  },
  copyBtn: {
    width: space(7),
    height: space(7),
    ...typesetter(-1),
    transitionProperty: 'backgroundColor',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '0.3s',

    ...merge(
      mq({
        until: 's',
        value: {
          '--click-area-size-override': space(7),
        },
      })
    ),
  },
  copyText: {
    opacity: 0,
    animationDuration: '3s',
    animationName: s9.keyframes({
      '0%': { opacity: 0 },
      '15%': { opacity: 1 },
      '85%': { opacity: 1 },
      '100%': { opacity: 0 },
    }),
    animationTimingFunction: 'ease-in-out',

    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  copyIcon: {
    fontSize: space(5),
    transition: 'all 0.3s ease-in-out',
    '--icon-color-override': color('neutral1300'),
  },
  copyIconLeave: {
    fontSize: space(5),
    transition: 'all 0.3s ease-in-out',
  },
  copyBtnWrapper: {
    alignSelf: 'center',
    justifySelf: 'end',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    columnGap: space(2),
  },
});

export interface LiveBlogItemShareButtonProps
  extends Pick<LiveBlogItemFragment, 'itemId' | 'itemUrl' | 'title'> {
  /** The Children to be rendered inside `<LiveBlogItemShareButton>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyLiveBlogItemShareButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <LiveBlogItemShareButton
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <LiveBlogItemShareButton styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

interface ButtonProps extends Pick<LiveBlogItemShareButtonProps, 'itemUrl' | 'itemId' | 'title'> {
  setShouldRender: React.Dispatch<React.SetStateAction<'shareBtn' | 'copyLinkBtn'>>;
  shareData: { url: string };
}

function ShareButton({ shareData, setShouldRender, title, itemId }: ButtonProps) {
  const biAction = useBi();
  const appUserAgent = useAppUserAgent();

  const liveBlogItemId = itemId.split('#')[0];

  const isAndroid = appUserAgent === 'android';
  async function onClick() {
    try {
      if (!isAndroid) {
        await navigator.share(shareData);
      }
      biAction({
        action_id: 14,
        feature: 'article page - liveblog item',
        campaign_name: 'share link',
        feature_type: 'Marketing',
      });
    } catch (err) {
      console.error(err instanceof Error ? err.message : err);
    }
  }

  const clickAreaProps = getShareButtonProps(isAndroid, itemId, title, liveBlogItemId, shareData);

  React.useEffect(() => {
    if (appUserAgent === 'android') {
      if (!itemId || !title || clickAreaProps.as === 'button') {
        setShouldRender('copyLinkBtn');
      } else {
        return;
      }
    }
    const shareSupported = navigator.canShare && navigator.canShare(shareData);

    if (!shareSupported) setShouldRender('copyLinkBtn');
  }, [appUserAgent, setShouldRender, shareData, itemId, title, clickAreaProps.as]);

  return (
    <ClickArea
      styleExtend={[c.shareBtn, c.clickAreaGeneralStyling, c.clickAreaColor]}
      rippleSize="small"
      data-testid="share-btn"
      {...clickAreaProps}
      onClick={onClick}
      title={fork({ default: 'שיתוף', hdc: 'Share' })}
    >
      <Icon icon="share" styleExtend={[c.shareBtnIcon]} variant="neutral" />
      <VisuallyHidden>{fork({ default: 'שיתוף', hdc: 'Share' })}</VisuallyHidden>
    </ClickArea>
  );
}

function getShareButtonProps(
  isAndroid: boolean,
  itemId: string | null | undefined,
  itemTitle: string | null | undefined,
  liveBlogItemId: string,
  shareData: { url: string }
) {
  if (isAndroid && itemId && itemTitle) {
    const searchParamsString = new URLSearchParams({
      liveBlogItemId,
      url: shareData.url.split('#')[0],
      text: itemTitle,
    }).toString();

    return {
      as: 'a',
      href: itemId && itemTitle ? `https://sharearticle/?${searchParamsString}` : undefined,
      rel: 'nonreferrer',
    } as const;
  }

  return { as: 'button' } as const;
}

function CopyLinkButton({ itemUrl }: Pick<ButtonProps, 'itemUrl'>) {
  const biAction = useBi();
  // const [copyBtnState, setCopyButtonState] = React.useState<
  // 'startingShowMessage' | 'stoppingShowingMessage' | 'hidden'
  // >('hidden');

  const [copied, setCopied] = React.useState(false);

  const copyOnClick = () => {
    if (itemUrl) {
      navigator?.clipboard?.writeText(itemUrl);
      setCopied(true);
      biAction({
        action_id: 14,
        feature: 'article page - liveblog item',
        campaign_name: 'copy link',
        feature_type: 'Marketing',
      });
    }
  };

  const copyBtnText = fork({ default: 'העתק לינק', hdc: 'Copy the link' });
  const copiedBtnText = fork({ default: 'הקישור הועתק', hdc: 'Link copied' });

  return (
    <div className={s9(c.copyBtnWrapper)}>
      <ClickArea
        styleExtend={[
          c.copyBtn,
          c.clickAreaGeneralStyling,
          copied ? c.coppiedSuccess : c.clickAreaColor,
        ]}
        rippleSize="small"
        data-testid="copy-btn"
        onClick={copyOnClick}
        title={copyBtnText}
      >
        <Icon
          icon={copied ? 'check' : 'link'}
          styleExtend={[c.copyIcon, copied && c.copyIconLeave]}
          // onAnimationEnd={() => setCopyButtonState('stoppingShowingMessage')}
          variant="neutral"
        />
        <VisuallyHidden>{copyBtnText}</VisuallyHidden>
      </ClickArea>
      {copied && (
        <span
          className={s9(copied && c.copyText)}
          onAnimationEnd={() => {
            setCopied(false);
          }}
        >
          {copiedBtnText}
        </span>
      )}
    </div>
  );
}

const LiveBlogItemShareButtonFallback = () => {
  return (
    <ClickArea
      styleExtend={[c.shareBtn, c.clickAreaGeneralStyling, c.clickAreaColor]}
      rippleSize="small"
      data-testid="share-btn-fallback"
    >
      <Icon icon="share" styleExtend={[c.shareBtnIcon]} variant="neutral" />
      <VisuallyHidden>{fork({ default: 'שיתוף', hdc: 'Share' })}</VisuallyHidden>
    </ClickArea>
  );
};

export default function LiveBlogItemShareButton({
  itemUrl,
  title,
  itemId,
}: LiveBlogItemShareButtonProps) {
  const platform = usePlatform();
  const [shouldRender, setShouldRender] = React.useState<'shareBtn' | 'copyLinkBtn'>(
    platform !== 'desktop' ? 'shareBtn' : 'copyLinkBtn'
  );

  const shareData = React.useMemo(() => {
    return { url: itemUrl || '' };
  }, [itemUrl]);

  if (!itemUrl) return null;

  const isApp = platform === 'app';

  const Wrapper = isApp ? NativeAppUserAgentContextProvider : React.Fragment;
  if (shouldRender === 'shareBtn') {
    return (
      <Wrapper fallback={<LiveBlogItemShareButtonFallback />}>
        <ShareButton
          shareData={shareData}
          setShouldRender={setShouldRender}
          title={title}
          itemId={itemId}
        />
      </Wrapper>
    );
  } else {
    return <CopyLinkButton itemUrl={itemUrl} />;
  }
}
