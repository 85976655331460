'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import Chip from '@haaretz/s-chip';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import useLiveBlog from '../LiveBlogProvider';

const c = s9.create({
  button: {
    ...typesetter(-1),
    top: space(6),
    position: 'sticky',
    height: space(11),
    zIndex: zIndex('tooltip'),
    display: 'flex',
    gridColumnStart: '1',
    gridColumnEnd: '-1',
    width: 'fit-content',
    justifySelf: 'center',
    alignItems: 'center',
    transform: 'scale(0)',
    animationFillMode: 'both',
    paddingInlineStart: space(5),
    paddingInlineEnd: space(5),

    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(0),
          paddingInlineStart: space(8),
          paddingInlineEnd: space(8),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  buttonAnimation: {
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in',
    animationName: s9.keyframes({
      '0%': {
        transform: 'scale(0)',
      },
      '1%': {
        transform: 'scale(0.75)',
      },
      '20%': {
        transform: 'scale(0.8)',
      },
      '50%': {
        transform: 'scale(1.05)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    }),
  },
  ssrButton: {
    transform: 'scale(1)',
  },
  scrolledButton: {
    borderColor: color('primary1000'),
  },
  scrolledLine: {
    height: '0',
    transitionProperty: 'height',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '0.03s',
  },
  buttonAppPaddingTop: {
    top: space(12),
  },
  chevron: {
    transform: 'rotate(270deg)',
  },
  bold: {
    fontWeight: '700',
  },
  featuredItemLineConnector: {
    backgroundColor: color('neutral300'),
    height: space(8),
    width: space(0.5),
    position: 'relative',
    insetInlineStart: '50%',
    transform: 'translateX(-50%)',
    marginTop: space(-4),
    marginBottom: space(-4),
  },
});

const baseBiData = {
  action_id: 169,
  feature_type: 'Content',
  feature: 'Liveblog',
  campaign_name: 'Liveblog Update',
};

interface LoadNewItemsButtonProps {
  onClick: () => void;
  newerItemsCount: number;
}

const observerOptions = {
  root: null,
  rootMargin: '-60px 0px 0px 0px',
  threshold: 0,
};

export default function LoadNewItemsButton({ onClick, newerItemsCount }: LoadNewItemsButtonProps) {
  const state = useLiveBlog();

  const platform = usePlatform();
  const biAction = useBi();
  const [scrolled, setScrolled] = React.useState(false);
  const lineConnectorRef = React.useRef<HTMLDivElement | null>(null);

  const updatesButtonRef = React.useRef<HTMLButtonElement | null>(null);

  let chipTextStart;
  let chipBoldText;
  let chipTextEnd;

  const itemsJSXToDisplayLength = state.itemsAbove.length;
  const ssrItemsJSXLength = state.featuredItemId && newerItemsCount;
  const itemsJSXLength = state.itemsAbovePreloaded.length || ssrItemsJSXLength || 0;

  if (state.featuredItemId && !itemsJSXToDisplayLength && newerItemsCount >= itemsJSXLength) {
    chipTextStart = fork({
      default: 'הצג',
      hdc: itemsJSXLength === 1 ? 'Show' : '',
    });

    chipBoldText = fork({
      default:
        itemsJSXLength === 1 ? 'עדכון' : itemsJSXLength > 1 ? `${itemsJSXLength} עדכונים` : '',
      hdc: itemsJSXLength > 1 ? `${itemsJSXLength} more` : '',
    });

    chipTextEnd = fork({
      default: itemsJSXLength === 1 ? 'נוסף' : 'נוספים',
      hdc: itemsJSXLength === 1 ? 'update' : 'updates',
    });
  } else {
    chipTextStart = fork({
      default: itemsJSXLength === 1 ? 'נוסף' : 'נוספו',
      hdc: itemsJSXLength === 1 ? 'New' : '',
    });

    chipBoldText = fork({
      default:
        itemsJSXLength === 1 ? 'עדכון' : itemsJSXLength > 1 ? `${itemsJSXLength} עדכונים` : '',
      hdc: itemsJSXLength > 1 ? `${itemsJSXLength} new` : '',
    });

    chipTextEnd = fork({
      default: itemsJSXLength === 1 ? 'חדש' : 'חדשים',
      hdc: itemsJSXLength === 1 ? 'update' : 'updates',
    });
  }

  const buttonText = `${chipTextStart} ${chipBoldText} ${chipTextEnd}`;

  const biData = {
    ...baseBiData,
    campaign_details: buttonText,
  };

  useImpressionObserver({
    elementRef: updatesButtonRef,
    biData,
  });

  React.useEffect(() => {
    const curr = lineConnectorRef.current;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setScrolled(!entry.isIntersecting);
      });
    }, observerOptions);

    if (curr) {
      observer.observe(curr);
    }

    return () => {
      if (curr) {
        observer.unobserve(curr);
      }
      observer.disconnect();
    };
  }, [itemsJSXLength]);

  if (!itemsJSXLength) return null;

  return (
    <>
      <Chip
        disabled={!state.itemsAbovePreloaded.length}
        onClick={() => {
          onClick();
          biAction(biData);
        }}
        styleExtend={[
          c.button,
          ssrItemsJSXLength ? c.ssrButton : c.buttonAnimation,
          platform === 'app' && c.buttonAppPaddingTop,
          scrolled && c.scrolledButton,
        ]}
        variant="liveBlog"
      >
        {chipTextStart}
        <span className={s9(itemsJSXLength > 1 && c.bold)}>{chipBoldText}</span>
        {chipTextEnd}
        <Icon icon="chevron" styleExtend={[c.chevron]} />
      </Chip>
      {state.featuredItemId ? (
        <div
          ref={lineConnectorRef}
          className={s9(c.featuredItemLineConnector, scrolled && c.scrolledLine)}
        />
      ) : null}
    </>
  );
}
