'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import LogoLoadingIndicator from '@haaretz/s-logo-loading-indicator';
import React from 'react';
import s9 from 'style9';

import useLiveBlog, { useLiveBlogActions } from '../LiveBlogProvider';

interface LiveBlogBaseItemsProps {
  initFirstItemId?: string | null;
  initLastItemId?: string | null;
  children: React.ReactNode[];
}

const c = s9.create({
  logoLoadingIndicator: {
    fontSize: space(15),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          fontSize: space(17),
        },
      }),
      mq({
        from: 'l',
        value: {
          fontSize: space(20),
        },
      })
    ),
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pendingDiv: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: space(8),
    height: '102%',
    width: '105%',
    margin: space(-4),
    zIndex: 2,
    justifyContent: 'center',
    backdropFilter: 'blur(2px)',
    backgroundColor: color('contentBg', { opacity: 0.7 }),
  },
});

export default function LiveBlogBaseItems({
  children,
  initFirstItemId,
  initLastItemId,
}: React.PropsWithChildren<LiveBlogBaseItemsProps>) {
  const initialFirstItemIdRef = React.useRef(initFirstItemId);
  const initialLastItemIdRef = React.useRef(initLastItemId);

  const state = useLiveBlog();
  const { setItemId } = useLiveBlogActions();

  React.useEffect(() => {
    if (
      initFirstItemId &&
      (!state.firstItemId || initialFirstItemIdRef.current !== initFirstItemId)
    ) {
      initialFirstItemIdRef.current = initFirstItemId;
      setItemId('FIRST', initFirstItemId);
    }
  }, [state.firstItemId, initFirstItemId, setItemId]);

  React.useEffect(() => {
    if (initLastItemId && (!state.lastItemId || initialLastItemIdRef.current !== initLastItemId)) {
      initialLastItemIdRef.current = initLastItemId;
      setItemId('LAST', initLastItemId);
    }
  }, [initLastItemId, state.lastItemId, setItemId]);

  return (
    <>
      {state.itemsLoading ? (
        <div className={s9(c.pendingDiv)}>
          <div className={s9(c.loadingWrapper)}>
            <LogoLoadingIndicator styleExtend={[c.logoLoadingIndicator]} />
          </div>
        </div>
      ) : null}
      {state.items.length ? state.items : children}
    </>
  );
}
